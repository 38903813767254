.newFormAgreement {
    display: flex;
    flex-direction: column;
    width: 40%;
}
.agreementActions a, .agreementActions a:hover {
    cursor: pointer;
}

.form-group.required .control-label:after {
    content:"*";
    color:red;
  }
  