body {
  margin: 0;
  padding: 0;
}
.header3 {
  background-color: #7d152b!important;
}
.topbar {
  background-color: #7d152b;
}
.topbar .top-social a {
  background: white;
}
.flaticon-social,
.fa-instagram,
.fa-whatsapp {
  color: #6b1e2e;
}
#navbar .navbar-nav li a {
  color: white !important;
}
.topbar .nav-text p {
  color: white;
}
.flaticon-black {
  color: white;
}
.flaticon-technology {
  color: white;
}
.img-portada {
  width: 100vw;
}
.servicesAreas {
  background-color: white;
}
.coloriP {
  background-color: white;
}
.h2-pi {
  font-size: 35px;
  color: #6b1e2e;
}
.panel-collapse {
  color: #6b1e2e;
}
.installations {
  background-color: #6b1e2e;
  margin-bottom: 30px;
}
.textTitle {
  color: white;
}
.titleColor {
  margin: 30px 0;
}
.paragraph {
  color: #6b1e2e;
}
.lead {
  color: #6b1e2e;
}
.faq-content {
  padding-top: 50px;
}
.faq-content {
  background-color: #6b1e2e;
}
h3.fQ,
.titleColor {
  color: white;
  font-size: 35px;
}
.colorProv {
  background-color: #6b1e2e;
}
.faq-title,
p {
  color: white;
}
.colorAgree {
  background-color: white;
}
footer {
  padding-top: 50px;
}
.footer-quote {
  max-width: 600px;
}
.h4footer {
  font-size: 30px;
}
.colorFooter {
  background-color: #6b1e2e;
}
.removeVideo {
  cursor: pointer;
}
.logoutContainer {
  padding-top: 12px;
}
.logoutLink {
  cursor: pointer;
}
.whatsapp-link {
  display: none !important;
}
p.videoDescription {
  padding: 10px !important;
}
.mainImage img {
  width: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
}
.div.logo {
  display: flex;
  width: 100%;
}
.logo {
  float: right;
  height: 40%;
  width: 40%;
}
.carousel-indicators .active {
  margin: 0;
  width: 30px;
  height: 3px;
  background-color: #fff;
}
.carousel-item img {
  height: 800px;
}

.presentational-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.newsletter-wrap #result {
  color: #6b1e2e !important;
}

.videos-container .textTitle {
  margin-top: 10px;
}

#newsSection {
  background: #6b1e2e;
}
#newsSection h3 {
  font-size: 35px;
  padding-top: 50px;
}

#newsSection article {
  display: flex;
  visibility: visible;
  animation-name: fadeInUp;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

#newsSection article p {
  font-family: "Crimson Text", serif;
  color: #fff;
  padding: 5px;
}

.agreement-item {
  margin-bottom: 20px;
}

#installationsSection .titleColor {
  margin-top: 50px;
}

iframe {
  width: 100%;
  height: 300px;
}

#navbar ul.navbar-nav li {
  padding: 0px 7px;
}

#newsSection .newsTitle {
  color: #fff;
}

.mainCarrousell{
  display: flex;
  justify-content: space-between;
}

.emptySpace {
  height: 20px;
  background: #7d152b;
}

#servicesSection p {
  color: #7d152b;
}

#servicesSection .titleColor {
  color: #7d152b;
}

#servicesSection {
  background-color: #fff;
  text-align: center;
}

@media (max-width: 800px) {
  .secondmap {
    margin-left: 0;
  }
  iframe {
    width: 100%;
    height: 300px;
  }
  .whatsapp-link {
    display: inline-block !important;
  }
  .resource-item iframe {
    margin-bottom: 20px;
  }

  .presentational-cards {
    flex-direction: column;
    justify-content: center;
  }

  .presentational-cards .flip > .front,
  .flip > .back {
    height: 350px;
    width: 90%;
  }

  .flip > .back {
    height: 350px !important;
  }

  .carousel-item img {
    height: 400px;
  }
}

@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");

.presentational-cards h1 {
  font-size: 2.2em;
}

.flip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flip > .front,
.flip > .back {
  display: block;

  transition-duration: 1s;
  transition-property: transform, opacity;
}
.flip > .front {
  transform: rotateY(0deg);
}
.flip > .back {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);
}
.flip:hover > .front {
  transform: rotateY(180deg);
}
.flip:hover > .back {
  opacity: 1;
  transform: rotateY(0deg);
  overflow-y: scroll;
}
.flip.flip-vertical > .back {
  transform: rotateX(-180deg);
}
.flip.flip-vertical:hover > .front {
  transform: rotateX(180deg);
}
.flip.flip-vertical:hover > .back {
  transform: rotateX(0deg);
}

.flip {
  position: relative;
  margin: 0 auto 1em;
  width: 100%;
  max-width: 400px;
}

.flip > .front,
.flip > .back {
  display: block;
  color: white;
  width: inherit;
  background-size: cover !important;
  background-position: center !important;
  height: 450px;
  padding: 1em 2em;
  background: #313131;
  border-radius: 10px;
}
.flip > .front p,
.flip > .back p {
  font-size: 0.9125rem;
  line-height: 160%;
  color: #999;
}

.text-shadow {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.04), 2px 2px rgba(0, 0, 0, 0.04),
    3px 3px rgba(0, 0, 0, 0.04), 4px 4px rgba(0, 0, 0, 0.04),
    0.125rem 0.125rem rgba(0, 0, 0, 0.04), 6px 6px rgba(0, 0, 0, 0.04),
    7px 7px rgba(0, 0, 0, 0.04), 8px 8px rgba(0, 0, 0, 0.04),
    9px 9px rgba(0, 0, 0, 0.04), 0.3125rem 0.3125rem rgba(0, 0, 0, 0.04),
    11px 11px rgba(0, 0, 0, 0.04), 12px 12px rgba(0, 0, 0, 0.04),
    13px 13px rgba(0, 0, 0, 0.04), 14px 14px rgba(0, 0, 0, 0.04),
    0.625rem 0.625rem rgba(0, 0, 0, 0.04), 16px 16px rgba(0, 0, 0, 0.04),
    17px 17px rgba(0, 0, 0, 0.04), 18px 18px rgba(0, 0, 0, 0.04),
    19px 19px rgba(0, 0, 0, 0.04), 1.25rem 1.25rem rgba(0, 0, 0, 0.04);
}
.contact .contact-form {
  border: 2px solid #eaeaea;
  padding: 25px 50px 40px;
}

.contact .contact-form i {
  font-size: 50px;
  margin: 0 0 10px;
  color: #e6ddd0;
  display: block;
}

.contact .contact-form .h2 {
  margin: 0 0 30px;
}

.contact .contact-form input {
  width: 100%;
  border: none;
  height: 30px;
  border-bottom: 2px solid #eaeaea;
  color: #65594d;
  font-size: 15px;
  margin: 0 0 28px;
  background: transparent;
}

.contact .contact-form textarea {
  width: 100%;
  border: none;
  height: 95px;
  border-bottom: 2px solid #eaeaea;
  color: #65594d;
  font-size: 15px;
  margin: 0 0 34px;
}

.contact .contact-form button {
  padding: 11px 40px;
}

.contact .contact-form2 input {
  width: 100%;
  border: none;
  height: 47px;
  color: #939393;
  font-size: 14px;
  padding: 0 24px;
  margin: 0 0 20px;
  background: #f4f4f4;
}

.contact .contact-form2 textarea {
  width: 100%;
  border: none;
  height: 178px;
  border-bottom: 2px solid #eaeaea;
  color: #939393;
  font-size: 14px;
  padding: 19px 24px;
  margin: 0 0 20px;
  background: #f4f4f4;
}

.contact .contact-form2 button {
  padding: 11px 40px;
}

.contact-form input::-webkit-input-placeholder {
  color: #65594d;
}

.contact-form input:-moz-placeholder {
  color: #65594d;
}

.contact-form input::-moz-placeholder {
  color: #65594d;
}

.contact-form input:-ms-input-placeholder {
  color: #65594d;
}

.contact-form textarea::-webkit-input-placeholder {
  color: #65594d;
}

.contact-form textarea:-moz-placeholder {
  color: #65594d;
}

.contact-form textarea::-moz-placeholder {
  color: #65594d;
}

.contact-form textarea:-ms-input-placeholder {
  color: #65594d;
}

::-webkit-scrollbar {
  display: none;
}

.homepage {
  display: flex;
}

#galleryAreaSection img{
  object-fit: cover;
}

.mainImage {
  height: 45vw;
}


@media (max-width: 1024px) {
  .homepage {
    flex-direction: column;
  }

  #galleryAreaSection {
    width: 100%;
  }

  .mainImage {
    width: 100%;
    overflow: hidden;
  }
}


@media (max-width: 767px) {
  #navbar .navbar-nav li a {
    color: #6b1e2e !important;
  }
}
